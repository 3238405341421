import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const PartDetailsStyles = styled.div`
  padding: 40px;
  border: 1px solid var(--black);
  figure {
    width: 50%;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
  @media (max-width: 499px) {
    padding: 0;
    border: 0;
  }
`;
const PartDataListStyles = styled.ul`
  li {
    display: flex;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    justify-content: space-between;
    :nth-child(odd) {
      background: var(--grey);
    }
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 767px) {
    li {
      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

export default function PartDetails({ details }) {
  return (
    <PartDetailsStyles>
      <figure>
        <Img fluid={details.image.asset.fluid} alt={details.name} />
      </figure>
      <PartDataListStyles>
        <li>
          <span>Material:</span>
          <span>steel</span>
        </li>
        <li>
          <span>Material:</span>
          <span>steel</span>
        </li>
        <li>
          <span>Material:</span>
          <span>steel</span>
        </li>
      </PartDataListStyles>
    </PartDetailsStyles>
  );
}
