import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';
import PartDetails from '../components/PartDetails';

export default function PartsDetailsPage({ data, pageContext }) {
  const details = data.part;
  console.log(pageContext);
  const breadcrumbs = [
    {
      title: 'Parts',
      url: '/parts',
    },
    {
      title: pageContext.familyName,
      url: `/parts/${pageContext.familyUrl}`,
    },
  ];
  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <PartDetails details={details} />
    </Layout>
  );
}

export const query = graphql`
  query PartQuery($url: String) {
    part: sanityPart(url: { current: { eq: $url } }) {
      id
      name
      family {
        name
        url {
          current
        }
        id
      }
      image {
        asset {
          fluid(maxWidth: 300) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
